@font-face {
	font-family: 'LeagueSpartan';
	src: url('./assets/font/LeagueSpartan-Bold.otf');
	font-weight: 100;
}

@font-face {
	font-family: 'MontserratItalic';
	src: url('./assets/font/Montserrat-Italic-VariableFont_wght.ttf');
	font-weight: 100;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/font/Montserrat-VariableFont_wght.ttf');
	font-weight: 100;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    scroll-behavior: smooth;
    scroll-padding-top: 75px;
  }
  
  body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #100216 !important;
    color: #fff !important;
    font-family: 'LeagueSpartan', sans-serif !important;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
  }
  a {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    line-height: normal;
    text-decoration: none;
    font-size: 1.7vh;
  }
  p, li, button, ul {
    color: rgb(175, 175, 175);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 10px 0px 0px 0px;
    line-height: normal;
    text-decoration: none;
    font-size: 1.7vh;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  button {
    border: 0;
    background-color: transparent;
  }
  
  input:focus, textarea:focus, select:focus {
    outline: none;
  }
  
  @media (min-width:1700px) {
      main .container {
          max-width: 100%;
          padding: 0 150px;
      }
  }

  /************ Navbar Css ************/
.navbar {
    padding: 0 0;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 9999;
    transition:  0.32s ease-in-out;
    background-color: #100216;
    opacity: 95%;
  }
  .navbar.scrolled {
    padding: 20px 0;
    top: -500px;
    width: 100%;
    background-color: #121212;
  }
  
  .navbar-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 18px;
    opacity: 0.65;
  }
  
  navbar-link:hover,
  .active {
      opacity: 1;
  }

  .social-icon {
      display: inline-block;
      margin-left: 14px;
  }
  .social-icon a {
      width: 50px;
      height: 60px;
      background: rgba(217, 217, 217, 0.1);
      display: inline-flex;
      border-radius: 30%;
      margin-right: 6px;
      align-items: center;
      justify-content: center;
      line-height: 1;
      clip-path: polygon(0% 25%,0% 75%,50% 100%,100% 75%,100% 25%,50% 0%);
      /*border: 1px solid rgba(255, 255, 255, 0.2);*/
  }
  .social-icon a::before {
      content: "";
      width: 50px;
      height: 60px;
      position: absolute;
      background-color: #ffffff;
      border-radius: 30%;
      opacity: 0%;
      transform: scale(0);
      transition: 1.0s ease-in-out;
  }
  .social-icon a:hover::before {
      transform: scale(1.5) rotate(500deg);
      opacity: 100%;
  }
  .social-icon a img {
      width: 40%;
      transition: 1.0s ease-in-out;
  }
  .social-icon a:hover img {
      filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
  }
  .navbar-button {
      font-weight: 700;
      color: #fff;
      border: 1px solid #fff;
      padding: 18px 34px;
      font-size: 18px;
      margin-left: 25px;
      position: relative;
      background-color: transparent;
      transition: 0.3s ease-in-out;
  }
  .navbar-button span {
      z-index: 1;
  }
  .navbar-button::before {
      content: "";
      opacity: 0%;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgb(255, 255, 255);
      left: 0;
      top: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
  }
  .navbar-button:hover {
    color: #100216;
    transition: 0.3s ease-in-out;
  }
  .navbar-button:hover::before {
      content: "";
      border-radius: 5px;
      opacity: 100%;
      width: 100%;
      height: 100%;
      position: absolute;
  }
/*** borders ***/
.line-1{
  position: fixed;
  border-right: 2px solid #888;
  display: block;
  height: 15%;
  right: 4%;
  bottom: 0%;
  width: 1px;
}

.line-2{
  position: fixed;
  border-right: 2px solid #AAA;
  display: block;
  height: 15%;
  left: 4%;
  bottom: 0%;
  width: 1px;
}

/*** content ***/
.intro{
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.intro-1{
  color: rgb(211, 228, 150);
  font-weight: 100;
  font-size: 4vh;
  font-family: Montserrat;
}

.intro-2{
  font-size: 4vh;
  font-family: LeagueSpartan;
  margin-top: 10px;
}

.intro-3{
  color: rgb(175, 175, 175);
  font-size: 3vh;
  font-family: LeagueSpartan;
  margin-top: 10px;
}

.details{
  color: rgb(175, 175, 175);
  font-size: 1.2vh;
  font-family: LeagueSpartan;
  margin-top: 15px;
}
.project-card{
  background-color: #120216;
}

.project-tags{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  list-style: none;
  bottom: 0;
}

.project-tag{
  font-family: LeagueSpartan;
  font-size: 1.2vh;
  background-color: #1c0925;
  padding: 3px 6px; 
  border-radius: 5px 5px 5px 5px;
}

